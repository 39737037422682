// This is not a problem to have as part of a static page because it is a free
// account.  However, if I need more calls then I'll need to get a paid API key
// which must be used on a backend.
const API_KEY = '03ea1215906e7e3db0dddaa1151678ec';

class OpenWeatherMap {
  // TODO: Fetch and display warnings or watches

  async getForecast(latitude, longitude /* units */) {
    console.log("Retrieving OpenWeatherMap Forecast...");
    var hourlyForecast = await this.fetchHourlyForecast(latitude, longitude);
    console.log("OpenWeatherMap forecast found: ");
    console.log(hourlyForecast);
    var weather = this.getCurrentWeather(hourlyForecast);
    var temperature = this.getCurrentTemperature(hourlyForecast, "metric");
    var wind = this.getCurrentWind(hourlyForecast, "metric");
    var sunrise = this.getCurrentSunrise(hourlyForecast);
    var sunset = this.getCurrentSunset(hourlyForecast);
    var city = this.getCity(hourlyForecast);

    return {
      weather: weather,
      temperature: temperature,
      wind: wind,
      sunrise: sunrise,
      sunset: sunset,
      city: city,
    };
  }

  async fetchHourlyForecast(latitude, longitude) {
    return await fetch(
      `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${API_KEY}&units=metric`
    ).then((response) => response.json());
  }

  getCurrentWind(hourlyForecast, units) {
    return {
      value: this.MpsToKph(hourlyForecast.wind.speed),
      units: units === "metric" ? "km/h" : "mph",
    };
  }

  MpsToKph(speed) {
    return speed * (18 / 5);
  }

  getCurrentWeather(hourlyForecast) {
    // TODO: OpenWeatherMap API actually returns a list of weather.  Therefore, the full list should be returned and the various conditions displayed.
    return {
      main: hourlyForecast.weather[0].main.toLowerCase(),
      description: hourlyForecast.weather[0].description.toLowerCase(),
    };
  }

  getCurrentTemperature(hourlyForecast, units) {
    return {
      value:
        units === "metric"
          ? hourlyForecast.main.temp
          : hourlyForecast.main.temp * 1.8 + 32.0,
      feels_like:
        units === "metric"
          ? hourlyForecast.main.feels_like.toFixed(1)
          : hourlyForecast.main.temp * 1.8 + 32.0,
      units: units === "metric" ? "C" : "F",
    };
  }

  getCurrentSunrise(hourlyForecast) {
    return this.unixTimeToDate(hourlyForecast.sys.sunrise);
  }

  getCurrentSunset(hourlyForecast) {
    return this.unixTimeToDate(hourlyForecast.sys.sunset);
  }

  getCity(hourlyForecast) {
    return hourlyForecast.name;
  }

  unixTimeToDate(time) {
    return new Date(time * 1000);
  }

  async findCities(searchString) {
    // Uses Geocoding API
    return await fetch(
      `https://api.openweathermap.org/geo/1.0/direct?q=${searchString}&limit=5&appid=${API_KEY}`
    )
      .then((response) => {
        return response.ok === true ? response.json() : [];
      })
      .catch((error) =>
        console.error(
          "There was a problem fetching from the OpenWeatherMap Geocoding API:",
          error
        )
      );
  }

  // TODO: Reverse geocoding to get province for Forecast city display:
  // https://openweathermap.org/api/geocoding-api
}

export default OpenWeatherMap;
